import Marquee from 'react-fast-marquee';
import style from './Partners.module.scss';

import logo1 from '../../sections/Main/assets/logo1.svg';
import logo2 from '../../sections/Main/assets/logo2.svg';
import logo3 from '../../sections/Main/assets/logo3.svg';
import logo4 from '../../sections/Main/assets/logo4.svg';
import logo5 from '../../sections/Main/assets/logo5.svg';
import Text from '../../../../components/Text';

const Partners = ({ className }) => {
  return (
    <div className={`${style.partners} ${className}`}>
      <Text uppercase grey fz16 center className={style.partners__title}>
        Partners
      </Text>
      <Marquee className={style.partners__marquee}>
        <div className={style.partners__marqueeRow}>
          <img src={logo1} alt='' />
          <img src={logo2} alt='' />
          <img src={logo3} alt='' />
          <img src={logo4} alt='' />
          <img src={logo5} alt='' />
        </div>
        <div className={style.partners__marqueeRow}>
          <img src={logo1} alt='' />
          <img src={logo2} alt='' />
          <img src={logo3} alt='' />
          <img src={logo4} alt='' />
          <img src={logo5} alt='' />
        </div>
        <div className={style.partners__marqueeRow}>
          <img src={logo1} alt='' />
          <img src={logo2} alt='' />
          <img src={logo3} alt='' />
          <img src={logo4} alt='' />
          <img src={logo5} alt='' />
        </div>
      </Marquee>
    </div>
  );
};

export default Partners;
