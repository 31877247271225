import { useMediaQuery } from 'usehooks-ts';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import Presale from '../../modules/Presale';
import style from './Main.module.scss';
import lights from './assets/lights.png';

const MainSection = () => {
  const isMobile = useMediaQuery('(max-width:991px)');

  return (
    <section className={style.main}>
      <div className={style.main__lights}>
        <img src={lights} alt='' />
      </div>
      <div className='__container'>
        <div className={style.mainBody}>
          <div className={style.mainContent}>
            <div className={style.mainContent__stars}>
              <svg
                width='48'
                height='47'
                viewBox='0 0 48 47'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M25.4168 9.50751L24 5.43142L22.5832 9.50751L19.164 19.3439L8.75253 19.556L4.43812 19.644L7.87688 22.251L16.1752 28.5424L13.1597 38.5099L11.9101 42.6403L15.4522 40.1755L24 34.2274L32.5478 40.1755L36.0899 42.6403L34.8403 38.5099L31.8248 28.5424L40.1231 22.251L43.5619 19.644L39.2475 19.556L28.836 19.3439L25.4168 9.50751Z'
                  fill='#FFAF00'
                  stroke='black'
                  stroke-width='3'
                />
              </svg>
              <svg
                width='48'
                height='47'
                viewBox='0 0 48 47'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M25.4168 9.50751L24 5.43142L22.5832 9.50751L19.164 19.3439L8.75253 19.556L4.43812 19.644L7.87688 22.251L16.1752 28.5424L13.1597 38.5099L11.9101 42.6403L15.4522 40.1755L24 34.2274L32.5478 40.1755L36.0899 42.6403L34.8403 38.5099L31.8248 28.5424L40.1231 22.251L43.5619 19.644L39.2475 19.556L28.836 19.3439L25.4168 9.50751Z'
                  fill='#FFAF00'
                  stroke='black'
                  stroke-width='3'
                />
              </svg>
              <svg
                width='48'
                height='47'
                viewBox='0 0 48 47'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M25.4168 9.50751L24 5.43142L22.5832 9.50751L19.164 19.3439L8.75253 19.556L4.43812 19.644L7.87688 22.251L16.1752 28.5424L13.1597 38.5099L11.9101 42.6403L15.4522 40.1755L24 34.2274L32.5478 40.1755L36.0899 42.6403L34.8403 38.5099L31.8248 28.5424L40.1231 22.251L43.5619 19.644L39.2475 19.556L28.836 19.3439L25.4168 9.50751Z'
                  fill='#FFAF00'
                  stroke='black'
                  stroke-width='3'
                />
              </svg>
              <svg
                width='48'
                height='47'
                viewBox='0 0 48 47'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M25.4168 9.50751L24 5.43142L22.5832 9.50751L19.164 19.3439L8.75253 19.556L4.43812 19.644L7.87688 22.251L16.1752 28.5424L13.1597 38.5099L11.9101 42.6403L15.4522 40.1755L24 34.2274L32.5478 40.1755L36.0899 42.6403L34.8403 38.5099L31.8248 28.5424L40.1231 22.251L43.5619 19.644L39.2475 19.556L28.836 19.3439L25.4168 9.50751Z'
                  fill='#FFAF00'
                  stroke='black'
                  stroke-width='3'
                />
              </svg>
              <svg
                width='48'
                height='47'
                viewBox='0 0 48 47'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M25.4168 9.50751L24 5.43142L22.5832 9.50751L19.164 19.3439L8.75253 19.556L4.43812 19.644L7.87688 22.251L16.1752 28.5424L13.1597 38.5099L11.9101 42.6403L15.4522 40.1755L24 34.2274L32.5478 40.1755L36.0899 42.6403L34.8403 38.5099L31.8248 28.5424L40.1231 22.251L43.5619 19.644L39.2475 19.556L28.836 19.3439L25.4168 9.50751Z'
                  fill='#FFAF00'
                  stroke='black'
                  stroke-width='3'
                />
              </svg>
            </div>
            <Title.H1 className={style.mainContent__title}>
              <Title.Row>Join the $BUll</Title.Row>
              <Title.Row>token presale</Title.Row>
            </Title.H1>
            {!isMobile && (
              <div className={style.mainContent__cards}>
                <div className={style.mainContent__card}>
                  <div className={style.mainContent__cardWrapper}>
                    <Text fz24 bold>
                      Unlock Access
                    </Text>
                    <Text grey>
                      The $BULL token is your key to accessing premium trading
                      features, revenue-sharing opportunities, and more on the
                      Bullionaire platform.
                    </Text>
                  </div>
                </div>
                <div className={style.mainContent__card}>
                  <div className={style.mainContent__cardWrapper}>
                    <Text fz24 bold>
                      Join Early
                    </Text>
                    <Text grey>
                      By participating in the presale, you can secure early
                      access, benefit from bonuses, and be part of Bullionaire
                      from the start. Our presale is structured to reward early
                      participants and provide maximum value.
                    </Text>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Presale className={style.main__presale} />
          {isMobile && (
            <div className={style.mainContent__cards}>
              <div className={style.mainContent__card}>
                <div className={style.mainContent__cardWrapper}>
                  <Text fz24 bold>
                    Unlock Access
                  </Text>
                  <Text grey>
                    The $BULL token is your key to accessing premium trading
                    features, revenue-sharing opportunities, and more on the
                    Bullionaire platform.
                  </Text>
                </div>
              </div>
              <div className={style.mainContent__card}>
                <div className={style.mainContent__cardWrapper}>
                  <Text fz24 bold>
                    Join Early
                  </Text>
                  <Text grey>
                    By participating in the presale, you can secure early
                    access, benefit from bonuses, and be part of Bullionaire
                    from the start. Our presale is structured to reward early
                    participants and provide maximum value.
                  </Text>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default MainSection;
