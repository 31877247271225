import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import style from './GetIt.module.scss';
import overlayImgLeft from './assets/overlay-left.png';
import overlayImgRight from './assets/overlay-right.png';
import overlayImgLeftMob from './assets/overlay-left-mob.png';
import overlayImgRightMob from './assets/overlay-right-mob.png';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { useRef } from 'react';
import { useMediaQuery } from 'usehooks-ts';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

const GetItSection = () => {
  const container = useRef();
  const overlayLeftRef = useRef();
  const overlayRightRef = useRef();
  const isMobile = useMediaQuery('(max-width:991px)');

  useGSAP(
    () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          start: 'top 20%',
          markers: false,
        },
      });

      tl.from(
        overlayLeftRef.current,
        {
          x: '-100%',
          duration: 0.7,
        },
        0
      );
      tl.from(
        overlayRightRef.current,
        {
          x: '100%',
          duration: 0.7,
        },
        0
      );
    },
    { scope: container }
  );

  return (
    <section ref={container} className={style.get}>
      <div className={style.getOverlay}>
        <div
          ref={overlayLeftRef}
          className={`${style.getOverlay__item} ${style.left}`}
        >
          <img src={isMobile ? overlayImgLeftMob : overlayImgLeft} alt='' />
        </div>
        <div
          ref={overlayRightRef}
          className={`${style.getOverlay__item} ${style.right}`}
        >
          <img src={isMobile ? overlayImgRightMob : overlayImgRight} alt='' />
        </div>
      </div>
      <div className='__container'>
        <div className={style.getBody}>
          <div className={style.getBody__stars}>
            <svg
              width='48'
              height='47'
              viewBox='0 0 48 47'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M25.4168 9.50751L24 5.43142L22.5832 9.50751L19.164 19.3439L8.75253 19.556L4.43812 19.644L7.87688 22.251L16.1752 28.5424L13.1597 38.5099L11.9101 42.6403L15.4522 40.1755L24 34.2274L32.5478 40.1755L36.0899 42.6403L34.8403 38.5099L31.8248 28.5424L40.1231 22.251L43.5619 19.644L39.2475 19.556L28.836 19.3439L25.4168 9.50751Z'
                fill='#FFAF00'
                stroke='black'
                stroke-width='3'
              />
            </svg>
            <svg
              width='48'
              height='47'
              viewBox='0 0 48 47'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M25.4168 9.50751L24 5.43142L22.5832 9.50751L19.164 19.3439L8.75253 19.556L4.43812 19.644L7.87688 22.251L16.1752 28.5424L13.1597 38.5099L11.9101 42.6403L15.4522 40.1755L24 34.2274L32.5478 40.1755L36.0899 42.6403L34.8403 38.5099L31.8248 28.5424L40.1231 22.251L43.5619 19.644L39.2475 19.556L28.836 19.3439L25.4168 9.50751Z'
                fill='#FFAF00'
                stroke='black'
                stroke-width='3'
              />
            </svg>
            <svg
              width='48'
              height='47'
              viewBox='0 0 48 47'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M25.4168 9.50751L24 5.43142L22.5832 9.50751L19.164 19.3439L8.75253 19.556L4.43812 19.644L7.87688 22.251L16.1752 28.5424L13.1597 38.5099L11.9101 42.6403L15.4522 40.1755L24 34.2274L32.5478 40.1755L36.0899 42.6403L34.8403 38.5099L31.8248 28.5424L40.1231 22.251L43.5619 19.644L39.2475 19.556L28.836 19.3439L25.4168 9.50751Z'
                fill='#FFAF00'
                stroke='black'
                stroke-width='3'
              />
            </svg>
            <svg
              width='48'
              height='47'
              viewBox='0 0 48 47'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M25.4168 9.50751L24 5.43142L22.5832 9.50751L19.164 19.3439L8.75253 19.556L4.43812 19.644L7.87688 22.251L16.1752 28.5424L13.1597 38.5099L11.9101 42.6403L15.4522 40.1755L24 34.2274L32.5478 40.1755L36.0899 42.6403L34.8403 38.5099L31.8248 28.5424L40.1231 22.251L43.5619 19.644L39.2475 19.556L28.836 19.3439L25.4168 9.50751Z'
                fill='#FFAF00'
                stroke='black'
                stroke-width='3'
              />
            </svg>
            <svg
              width='48'
              height='47'
              viewBox='0 0 48 47'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M25.4168 9.50751L24 5.43142L22.5832 9.50751L19.164 19.3439L8.75253 19.556L4.43812 19.644L7.87688 22.251L16.1752 28.5424L13.1597 38.5099L11.9101 42.6403L15.4522 40.1755L24 34.2274L32.5478 40.1755L36.0899 42.6403L34.8403 38.5099L31.8248 28.5424L40.1231 22.251L43.5619 19.644L39.2475 19.556L28.836 19.3439L25.4168 9.50751Z'
                fill='#FFAF00'
                stroke='black'
                stroke-width='3'
              />
            </svg>
          </div>
          <Title.H2 big center className={style.getBody__title}>
            <>
              <Title.Row>Get in early & </Title.Row>
              <Title.Row>secure</Title.Row>
              <Title.Row>your place</Title.Row>
            </>

            <div className={style.getBody__line}></div>
          </Title.H2>
          <Text className={style.getBody__text} center>
            Bullionaire is setting a new standard in luxury crypto.
          </Text>
          <Button className={style.getBody__btn}>
            Claim your $BULL <span>Today!</span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default GetItSection;
