import { useMediaQuery } from 'usehooks-ts';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import style from './Tocenomics.module.scss';

import img from './assets/img.jpg';
import lightsLeft from './assets/lights-left.png';
import lightsRight from './assets/lights-right.png';

const TokenomicsSection = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <section className={style.tokenomics}>
      <div className={`${style.tokenomics__lights} ${style.left}`}>
        <img src={lightsLeft} alt='' />
      </div>
      <div className={`${style.tokenomics__lights} ${style.right}`}>
        <img src={lightsRight} alt='' />
      </div>
      <div className='__container'>
        <Title.H1 big center className={style.tokenomics__title}>
          <Title.Row>Bullnomics</Title.Row>
        </Title.H1>
        <div className={style.tokenomicsBody}>
          <div className={`${style.tokenomicsBody__img}`}>
            <img src={img} alt='' />
          </div>
          <div className={`${style.tokenomicsBody__value}`}>
            1,000,000,000
            <span>1,000,000,000</span>
          </div>
          <Text
            center
            semiBold
            uppercase
            className={`${style.tokenomicsBody__total}`}
          >
            Total Supply $BULL
          </Text>
          <div className={`${style.tokenomicsBody__stats}`}>
            {!isMobile && (
              <>
                <div
                  className={`${style.tokenomicsBody__statsItem} ${style.red}`}
                >
                  <Text black fz16>
                    5% Dev
                  </Text>
                  <svg
                    width='14'
                    height='15'
                    viewBox='0 0 14 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M7.0013 9.8328V7.49947M7.0013 5.16614H7.00714M12.8346 7.49947C12.8346 10.7211 10.223 13.3328 7.0013 13.3328C3.77964 13.3328 1.16797 10.7211 1.16797 7.49947C1.16797 4.27781 3.77964 1.66614 7.0013 1.66614C10.223 1.66614 12.8346 4.27781 12.8346 7.49947Z'
                      stroke='black'
                      stroke-width='1.2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
                <div
                  className={`${style.tokenomicsBody__statsItem} ${style.yellow}`}
                >
                  <Text black fz16>
                    10% Reserve
                  </Text>
                  <svg
                    width='14'
                    height='15'
                    viewBox='0 0 14 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M7.0013 9.8328V7.49947M7.0013 5.16614H7.00714M12.8346 7.49947C12.8346 10.7211 10.223 13.3328 7.0013 13.3328C3.77964 13.3328 1.16797 10.7211 1.16797 7.49947C1.16797 4.27781 3.77964 1.66614 7.0013 1.66614C10.223 1.66614 12.8346 4.27781 12.8346 7.49947Z'
                      stroke='black'
                      stroke-width='1.2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
                <div
                  className={`${style.tokenomicsBody__statsItem} ${style.pink}`}
                >
                  <Text black fz16>
                    25% Marketing
                  </Text>
                  <svg
                    width='14'
                    height='15'
                    viewBox='0 0 14 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M7.0013 9.8328V7.49947M7.0013 5.16614H7.00714M12.8346 7.49947C12.8346 10.7211 10.223 13.3328 7.0013 13.3328C3.77964 13.3328 1.16797 10.7211 1.16797 7.49947C1.16797 4.27781 3.77964 1.66614 7.0013 1.66614C10.223 1.66614 12.8346 4.27781 12.8346 7.49947Z'
                      stroke='black'
                      stroke-width='1.2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
                <div
                  className={`${style.tokenomicsBody__statsItem} ${style.blue}`}
                >
                  <Text black fz16>
                    30% Rewards
                  </Text>
                  <svg
                    width='14'
                    height='15'
                    viewBox='0 0 14 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M7.0013 9.8328V7.49947M7.0013 5.16614H7.00714M12.8346 7.49947C12.8346 10.7211 10.223 13.3328 7.0013 13.3328C3.77964 13.3328 1.16797 10.7211 1.16797 7.49947C1.16797 4.27781 3.77964 1.66614 7.0013 1.66614C10.223 1.66614 12.8346 4.27781 12.8346 7.49947Z'
                      stroke='black'
                      stroke-width='1.2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
                <div
                  className={`${style.tokenomicsBody__statsItem} ${style.purple}`}
                >
                  <Text black fz16>
                    30% Rewards Staking Pools
                  </Text>
                  <svg
                    width='14'
                    height='15'
                    viewBox='0 0 14 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M7.0013 9.8328V7.49947M7.0013 5.16614H7.00714M12.8346 7.49947C12.8346 10.7211 10.223 13.3328 7.0013 13.3328C3.77964 13.3328 1.16797 10.7211 1.16797 7.49947C1.16797 4.27781 3.77964 1.66614 7.0013 1.66614C10.223 1.66614 12.8346 4.27781 12.8346 7.49947Z'
                      stroke='black'
                      stroke-width='1.2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
              </>
            )}
            {isMobile && (
              <>
                <div className={`${style.tokenomicsBody__statsRow}`}>
                  <div className={`${style.item} ${style.blue}`}>
                    <Text black fz16>
                      25% Rewards
                    </Text>
                    <svg
                      width='14'
                      height='15'
                      viewBox='0 0 14 15'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.0013 9.8328V7.49947M7.0013 5.16614H7.00714M12.8346 7.49947C12.8346 10.7211 10.223 13.3328 7.0013 13.3328C3.77964 13.3328 1.16797 10.7211 1.16797 7.49947C1.16797 4.27781 3.77964 1.66614 7.0013 1.66614C10.223 1.66614 12.8346 4.27781 12.8346 7.49947Z'
                        stroke='black'
                        stroke-width='1.2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </div>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className={`${style.tokenomicsBody__statsRow}`}>
                  <div className={`${style.item} ${style.purple}`}>
                    <Text black fz16>
                      35% Staking Pools
                    </Text>
                    <svg
                      width='14'
                      height='15'
                      viewBox='0 0 14 15'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.0013 9.8328V7.49947M7.0013 5.16614H7.00714M12.8346 7.49947C12.8346 10.7211 10.223 13.3328 7.0013 13.3328C3.77964 13.3328 1.16797 10.7211 1.16797 7.49947C1.16797 4.27781 3.77964 1.66614 7.0013 1.66614C10.223 1.66614 12.8346 4.27781 12.8346 7.49947Z'
                        stroke='black'
                        stroke-width='1.2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </div>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className={`${style.tokenomicsBody__statsRow}`}>
                  <div className={`${style.item} ${style.pink}`}>
                    <Text black fz16>
                      25% Marketing
                    </Text>
                    <svg
                      width='14'
                      height='15'
                      viewBox='0 0 14 15'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.0013 9.8328V7.49947M7.0013 5.16614H7.00714M12.8346 7.49947C12.8346 10.7211 10.223 13.3328 7.0013 13.3328C3.77964 13.3328 1.16797 10.7211 1.16797 7.49947C1.16797 4.27781 3.77964 1.66614 7.0013 1.66614C10.223 1.66614 12.8346 4.27781 12.8346 7.49947Z'
                        stroke='black'
                        stroke-width='1.2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </div>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className={`${style.tokenomicsBody__statsRow}`}>
                  <div className={`${style.item} ${style.red}`}>
                    <Text black fz16>
                      5% Dev
                    </Text>
                    <svg
                      width='14'
                      height='15'
                      viewBox='0 0 14 15'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.0013 9.8328V7.49947M7.0013 5.16614H7.00714M12.8346 7.49947C12.8346 10.7211 10.223 13.3328 7.0013 13.3328C3.77964 13.3328 1.16797 10.7211 1.16797 7.49947C1.16797 4.27781 3.77964 1.66614 7.0013 1.66614C10.223 1.66614 12.8346 4.27781 12.8346 7.49947Z'
                        stroke='black'
                        stroke-width='1.2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </div>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className={`${style.tokenomicsBody__statsRow}`}>
                  <div className={`${style.item} ${style.yellow}`}>
                    <Text black fz16>
                      10% Reserve
                    </Text>
                    <svg
                      width='14'
                      height='15'
                      viewBox='0 0 14 15'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.0013 9.8328V7.49947M7.0013 5.16614H7.00714M12.8346 7.49947C12.8346 10.7211 10.223 13.3328 7.0013 13.3328C3.77964 13.3328 1.16797 10.7211 1.16797 7.49947C1.16797 4.27781 3.77964 1.66614 7.0013 1.66614C10.223 1.66614 12.8346 4.27781 12.8346 7.49947Z'
                        stroke='black'
                        stroke-width='1.2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </div>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TokenomicsSection;
