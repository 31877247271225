import { useMediaQuery } from 'usehooks-ts';
import style from './Legal.module.scss';

import lightsLeft from './assets/lights-left.png';
import lightsRight from './assets/lights-right.png';
import lightsLeftMob from './assets/lights-left-mob.png';
import lightsRightMob from './assets/lights-right-mob.png';
import Title from '../../../../components/Title';
import Text from '../../../../components/Text';

const LegalSection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <section className={style.built}>
      {isMobile && (
        <>
          <div className={`${style.built__lights} ${style.left}`}>
            <img src={lightsLeftMob} alt='' />
          </div>
          <div className={`${style.built__lights} ${style.right}`}>
            <img src={lightsRightMob} alt='' />
          </div>
        </>
      )}
      {!isMobile && (
        <>
          <div className={`${style.built__lights} ${style.left}`}>
            <img src={lightsLeft} alt='' />
          </div>
          <div className={`${style.built__lights} ${style.right}`}>
            <img src={lightsRight} alt='' />
          </div>
        </>
      )}

      <div className='__container'>
        <div className={style.builtTop}>
          <div className={style.builtTop__text}>
            <Title.H2>
              <Title.Row>Legal</Title.Row>
              <Title.Row>Disclaimer</Title.Row>
            </Title.H2>
            <div className={style.builtTop__stars}>
              <svg
                width='36'
                height='34'
                viewBox='0 0 36 34'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18.9446 6.67167L18 3.95428L17.0554 6.67167L14.4625 14.1311L6.56695 14.292L3.69067 14.3506L5.98318 16.0887L12.2763 20.8598L9.98942 28.4186L9.15635 31.1722L11.5178 29.529L18 25.0183L24.4822 29.529L26.8437 31.1722L26.0106 28.4186L23.7237 20.8598L30.0168 16.0887L32.3093 14.3506L29.4331 14.292L21.5375 14.1311L18.9446 6.67167Z'
                  fill='#FFAF00'
                  stroke='black'
                  stroke-width='2'
                />
              </svg>
              <svg
                width='36'
                height='34'
                viewBox='0 0 36 34'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18.9446 6.67167L18 3.95428L17.0554 6.67167L14.4625 14.1311L6.56695 14.292L3.69067 14.3506L5.98318 16.0887L12.2763 20.8598L9.98942 28.4186L9.15635 31.1722L11.5178 29.529L18 25.0183L24.4822 29.529L26.8437 31.1722L26.0106 28.4186L23.7237 20.8598L30.0168 16.0887L32.3093 14.3506L29.4331 14.292L21.5375 14.1311L18.9446 6.67167Z'
                  fill='#FFAF00'
                  stroke='black'
                  stroke-width='2'
                />
              </svg>
              <svg
                width='36'
                height='34'
                viewBox='0 0 36 34'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18.9446 6.67167L18 3.95428L17.0554 6.67167L14.4625 14.1311L6.56695 14.292L3.69067 14.3506L5.98318 16.0887L12.2763 20.8598L9.98942 28.4186L9.15635 31.1722L11.5178 29.529L18 25.0183L24.4822 29.529L26.8437 31.1722L26.0106 28.4186L23.7237 20.8598L30.0168 16.0887L32.3093 14.3506L29.4331 14.292L21.5375 14.1311L18.9446 6.67167Z'
                  fill='#FFAF00'
                  stroke='black'
                  stroke-width='2'
                />
              </svg>
              <svg
                width='36'
                height='34'
                viewBox='0 0 36 34'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18.9446 6.67167L18 3.95428L17.0554 6.67167L14.4625 14.1311L6.56695 14.292L3.69067 14.3506L5.98318 16.0887L12.2763 20.8598L9.98942 28.4186L9.15635 31.1722L11.5178 29.529L18 25.0183L24.4822 29.529L26.8437 31.1722L26.0106 28.4186L23.7237 20.8598L30.0168 16.0887L32.3093 14.3506L29.4331 14.292L21.5375 14.1311L18.9446 6.67167Z'
                  fill='#FFAF00'
                  stroke='black'
                  stroke-width='2'
                />
              </svg>
              <svg
                width='36'
                height='34'
                viewBox='0 0 36 34'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18.9446 6.67167L18 3.95428L17.0554 6.67167L14.4625 14.1311L6.56695 14.292L3.69067 14.3506L5.98318 16.0887L12.2763 20.8598L9.98942 28.4186L9.15635 31.1722L11.5178 29.529L18 25.0183L24.4822 29.529L26.8437 31.1722L26.0106 28.4186L23.7237 20.8598L30.0168 16.0887L32.3093 14.3506L29.4331 14.292L21.5375 14.1311L18.9446 6.67167Z'
                  fill='#FFAF00'
                  stroke='black'
                  stroke-width='2'
                />
              </svg>
            </div>
          </div>
        </div>
        <div className={style.builtBody}>
          <div className={style.builtCard}>
            <Text className={`${style.builtCard__title}`}>
              <Title.Row>High-Risk Alert</Title.Row>
            </Text>
            <Text className={style.builtCard__text}>
              Bullionaire Coin is intended for individuals who understand the
              high-risk nature of cryptocurrency.
            </Text>
          </div>
          <div className={style.builtCard}>
            <Text className={style.builtCard__title}>
              <Title.Row>Market Volatility</Title.Row>
            </Text>
            <Text className={style.builtCard__text}>
              The crypto market is volatile, and participants should only use
              funds they can afford to lose. This document does not constitute
              financial advice, and Bullionaire makes no guarantees regarding
              future value.
            </Text>
          </div>
          <div className={style.builtCard}>
            <Text className={style.builtCard__title}>
              <Title.Row>Do Your Research</Title.Row>
            </Text>
            <Text className={style.builtCard__text}>
              Please conduct thorough research and consider consulting a
              financial advisor before engaging in any cryptocurrency
              transactions.
            </Text>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LegalSection;
