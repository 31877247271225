import style from './Safety.module.scss';
import Title from '../../../../components/Title';
import Text from '../../../../components/Text';
import { useEffect, useRef, useState } from 'react';
import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { useMediaQuery } from 'usehooks-ts';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

const SafetySection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const container = useRef(null);
  const progressLine = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);

  useGSAP(
    () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          start: 'top',
          end: 'bottom',
          pin: true,
          markers: false,
          onUpdate: (self) => {
            setCurrentProgress(self.progress * 100);
            progressLine.current.style.width = `${self.progress * 100}%`;
            if (self.progress < 0.25) {
              setCurrentIndex(0);
            } else if (self.progress >= 0.25 && self.progress < 0.5) {
              setCurrentIndex(1);
            } else if (self.progress >= 0.5 && self.progress < 0.75) {
              setCurrentIndex(2);
            } else if (self.progress >= 0.75) {
              setCurrentIndex(3);
            }
          },
        },
      });
    },
    { scope: container }
  );

  return (
    <section ref={container} className={style.safety}>
      <div className={style.safety__progress}>
        <span ref={progressLine}></span>
      </div>
      <div className='__container'>
        <div className={style.safetyBody}>
          <Title.H2 center={isMobile} className={style.safetyBody__title}>
            {!isMobile && <Title.Row>Ensuring Safety</Title.Row>}
            {isMobile && (
              <>
                <Title.Row>Ensuring</Title.Row>
                <Title.Row>Safety</Title.Row>
              </>
            )}
          </Title.H2>
          <div className={style.safetyBody__img}>
            <div
              className={`${style.safetyBody__imgItem} ${
                currentIndex === 0 && style.active
              }`}
            >
              <img src={img1} alt='' />
            </div>
            <div
              className={`${style.safetyBody__imgItem} ${
                currentIndex === 1 && style.active
              }`}
            >
              <img src={img2} alt='' />
            </div>
            <div
              className={`${style.safetyBody__imgItem} ${
                currentIndex === 2 && style.active
              }`}
            >
              <img src={img3} alt='' />
            </div>
            <div
              className={`${style.safetyBody__imgItem} ${
                currentIndex === 3 && style.active
              }`}
            >
              <img src={img1} alt='' />
            </div>
          </div>
          <div className={style.safetyBody__text}>
            <Accordion
              isActive={currentIndex === 0}
              progress={currentProgress * 4}
              title={'Real-Time Monitoring'}
              text={
                'Our continuous monitoring systems detect and address any potential issues swiftly, enhancing security at every step.'
              }
            />
            <Accordion
              isActive={currentIndex === 1}
              progress={(currentProgress - 25) * 4}
              title={'Audited Smart Contracts'}
              text={
                'Each of our smart contracts undergoes comprehensive security audits by trusted third-party firms, ensuring all transactions within the Bullionaire ecosystem are safe and reliable.'
              }
            />
            <Accordion
              isActive={currentIndex === 2}
              progress={(currentProgress - 50) * 4}
              title={'Frequent Updates'}
              text={
                'We believe in clear, proactive communication, regularly updating our community on progress, partnerships, and new features.'
              }
            />
            <Accordion
              isActive={currentIndex === 3}
              progress={(currentProgress - 75) * 4}
              title={'Secure Partnerships'}
              text={
                'Bullionaire collaborates only with vetted, high-security partners, reinforcing our commitment to maintaining the highest standards of safety and reliability.'
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const Accordion = ({ title, text, isActive, progress }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
    } else {
      contentRef.current.style.height = 0;
    }
  }, [isActive]);

  return (
    <div className={`${style.safetyAccordion} ${isActive && style.active}`}>
      <Text bold className={style.safetyAccordion__title}>
        {title}
      </Text>
      <div ref={contentRef} className={`${style.safetyAccordion__content}`}>
        <Text className={style.safetyAccordion__text}>{text}</Text>
      </div>
      <div className={style.safetyAccordion__line}>
        <span style={{ width: `${progress}%` }}></span>
      </div>
    </div>
  );
};

export default SafetySection;
