import { useMediaQuery } from 'usehooks-ts';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import style from './Staking.module.scss';

import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import { useRef } from 'react';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

const StakingSection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <section className={style.staking}>
      <div className='__container'>
        <div className={style.stakingTop}>
          <Title.H2 center={isMobile}>
            {!isMobile && <Title.Row>Staking & Rewards</Title.Row>}
            {isMobile && (
              <>
                <Title.Row>Staking & </Title.Row>
                <Title.Row>Rewards </Title.Row>
              </>
            )}
          </Title.H2>
          <Text center={isMobile}>
            With Bullionaire, loyalty is transformed into a valuable, unique
            asset through our NFT Pass system.
          </Text>
        </div>
        <div className={style.stakingBody}>
          <Card
            labelColor={'#ffaf00'}
            label={
              <>
                <span>Puppy Tier</span> · 0 - 3 Months
              </>
            }
            img={img1}
            name={'Entry-Level NFT Pass'}
            description={
              ' Your journey begins with the Puppy Pass, providing access to community events, early updates, and exclusive giveaways as a welcome to the Bullionaire circle.'
            }
          />
          <Card
            labelColor={'#FF4F4F'}
            label={
              <>
                <span>Adult Dog Tier</span> · 3 - 12 Months
              </>
            }
            img={img2}
            name={'Mid-Level NFT Pass'}
            description={
              'The Adult Dog Pass enhances your experience with priority access to events, increased staking rewards, and entry into luxury experiences with Bullionaire’s partners.'
            }
          />
          <Card
            labelColor={'#6ADA83'}
            label={
              <>
                <span>Alpha Dog Tier</span> · 12 Months
              </>
            }
            img={img3}
            name={'Entry-Level NFT Pass'}
            description={
              'The Alpha Dog Pass grants maximum rewards, including private jet experiences, a dedicated concierge service, and invitations to elite Bullionaire events.'
            }
          />
        </div>
      </div>
    </section>
  );
};

const Card = ({ labelColor, label, img, name, description }) => {
  const container = useRef();
  const dogImgRef = useRef();

  useGSAP(
    () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          start: 'top 70%',
          markers: false,
        },
      });

      tl.from(dogImgRef.current, {
        y: '50%',
        opacity: 0,
        duration: 0.6,
      });
    },
    { scope: container }
  );

  return (
    <div ref={container} className={style.stakingCard}>
      <div
        style={{ background: labelColor }}
        className={style.stakingCard__label}
      >
        {label}
      </div>
      <div className={style.stakingCard__img}>
        <img ref={dogImgRef} src={img} alt='' />
      </div>
      <Text center className={style.stakingCard__name}>
        <Title.Row>{name}</Title.Row>
      </Text>
      <Text center className={style.stakingCard__description}>
        {description}
      </Text>
    </div>
  );
};

export default StakingSection;
