import style from './Text.module.scss';

const Text = ({
  className,
  center,
  fz16,
  fz24,
  grey,
  semiBold,
  bold,
  black,
  uppercase,
  children,
}) => {
  return (
    <div
      className={`
      ${style.text} 
      ${fz16 && style.fz16}  
      ${fz24 && style.fz24}  
      ${semiBold && style.semiBold}
      ${bold && style.bold}
      ${grey && style.grey}
      ${black && style.black}
      ${uppercase && style.uppercase}
      ${center && style.center}
      ${className} 
      `}
    >
      {children}
    </div>
  );
};

export default Text;
