import { Swiper, SwiperSlide } from 'swiper/react';
import Title from '../../../../components/Title';
import style from './Roadmap.module.scss';
import bg from './assets/bg.png';
import lights from './assets/lights.png';
import lightsMob from './assets/lights-mob.png';
import Text from '../../../../components/Text';

import 'swiper/css';
import './index.scss';
import { Controller, Navigation } from 'swiper/modules';
import { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from 'usehooks-ts';

const phase1 = [
  {
    color: '#9BD969',
    name: 'Public Presale with Tiered Rewards',
  },
  {
    color: '#F15533',
    name: 'Early Supporters',
  },
  {
    color: '#4AE993',
    name: 'Access to the elite of Bullionaire',
  },
  {
    color: '#4EB1E9',
    name: 'Launching an exclusive website',
  },
  {
    color: '#C261EF',
    name: 'Public presale',
  },
  {
    color: '#EBA100',
    name: 'Awards for participants',
  },
  {
    color: '#EFE161',
    name: 'Better conditions',
  },
  {
    color: '#EFE161',
    name: 'Early access to $BULL',
  },
  {
    color: '#526EFF',
    name: 'Participation prior to mass launch',
  },
];
const phase2 = [
  {
    color: '#EBA100',
    name: 'Staking and loyalty',
  },
  {
    color: '#4AE993',
    name: 'Dedicated loyal holders',
  },
  {
    color: '#C261EF',
    name: 'Premium travel experiences',
  },
  {
    color: '#526EFF',
    name: 'Rewards system live',
  },
  {
    color: '#EFE161',
    name: 'Exclusive holder perks',
  },
  {
    color: '#9BD969',
    name: 'Fully payable $BULL',
  },
  {
    color: '#F15533',
    name: 'Elevate your lifestyle',
  },
  {
    color: '#F054A8',
    name: 'Bullionaire program advantage',
  },
  {
    color: '#EF9761',
    name: 'Dining and wellness',
  },
  {
    color: '#4EB1E9',
    name: 'Longer stay gains',
  },
];
const phase3 = [
  {
    color: '#EBA100',
    name: 'Bull Pay Integration',
  },
  {
    color: '#4AE993',
    name: 'Luxury Services Access',
  },
  {
    color: '#C261EF',
    name: 'Private Jets & Dining',
  },
  {
    color: '#526EFF',
    name: 'Exclusive Privileges',
  },
  {
    color: '#CAEF61',
    name: 'Claim NFTs Now',
  },
  {
    color: '#EFE161',
    name: 'Currency of Exclusivity',
  },
  {
    color: '#9BD969',
    name: 'NFT Collection Launch',
  },
  {
    color: '#F15533',
    name: 'Unique Experiences',
  },
  {
    color: '#F054A8',
    name: 'Major Exchange Listings',
  },
  {
    color: '#EF9761',
    name: 'Global Audience Access',
  },
  {
    color: '#4EB1E9',
    name: 'Join Bullionaire Rise',
  },
];
const phase4 = [
  {
    color: '#EBA100',
    name: 'Luxury Partnerships',
  },
  {
    color: '#4AE993',
    name: 'World-Class Brands',
  },
  {
    color: '#C261EF',
    name: 'Exclusive Offers',
  },
  {
    color: '#526EFF',
    name: 'Global Community',
  },
  {
    color: '#EFE161',
    name: 'DAO-Driven Future',
  },
  {
    color: '#CAEF61',
    name: 'Holder Voting',
  },
  {
    color: '#9BD969',
    name: 'New Partnerships',
  },
  {
    color: '#F15533',
    name: 'Luxury Crypto Direction',
  },
  {
    color: '#F054A8',
    name: 'Bullionaire Icon',
  },
  {
    color: '#EF9761',
    name: 'Strategic PR',
  },
  {
    color: '#4EB1E9',
    name: 'Influencer Alliances',
  },
];

const RoadmapSection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const swiper1Ref = useRef(null);
  const swiper2Ref = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const handleSwiper1Init = (swiper) => {
    swiper1Ref.current = swiper;
  };

  const handleSwiper2Init = (swiper) => {
    swiper2Ref.current = swiper;
  };

  useEffect(() => {
    // Проверяем, что оба слайдера инициализированы
    if (swiper1Ref.current && swiper2Ref.current) {
      // Связываем контроллеры слайдеров
      swiper1Ref.current.controller.control = swiper2Ref.current;
      swiper2Ref.current.controller.control = swiper1Ref.current;
      setIsInitialized(true); // После инициализации ставим флаг
    }
  }, [swiper1Ref.current, swiper2Ref.current]); // Следим за изменениями ссылки на слайдеры

  return (
    <section className={style.roadmap}>
      <div className={style.roadmap__lights}>
        <img src={isMobile ? lightsMob : lights} alt='' />
      </div>

      <div className='__container'>
        <div className={style.roadmapTop}>
          <div className={style.roadmapTop__img}>
            <img src={bg} alt='' />
          </div>
          <Title.H1 big center className={style.roadmapTop__title}>
            <Title.Row>roadmap</Title.Row>
          </Title.H1>
        </div>

        <div className={style.roadmapBody}>
          <div className={style.roadmapBody__phase}>
            <button
              className={`${style.roadmapBody__phaseNav} phase-navigation-prev`}
            >
              <svg
                width='8'
                height='11'
                viewBox='0 0 8 11'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M8 5.5L5.1656e-07 10.5L9.53674e-07 0.5L8 5.5Z'
                  fill='black'
                />
              </svg>
            </button>
            <Swiper
              id='swiper1'
              modules={[Navigation, Controller]}
              spaceBetween={0}
              slidesPerView={1}
              className={style.roadmapBody__phaseSlider}
              onSwiper={handleSwiper1Init} // Инициализация первого слайдера
              navigation={{
                prevEl: '.phase-navigation-prev',
                nextEl: '.phase-navigation-next',
              }}
            >
              <SwiperSlide>
                <div className={style.text}>
                  <Text center fz16 semiBold>
                    PHASE 1
                  </Text>
                  <Text center>The Big Debut</Text>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.text}>
                  <Text center fz16 semiBold>
                    PHASE 2
                  </Text>
                  <Text center>Building the Inner Circle</Text>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.text}>
                  <Text center fz16 semiBold>
                    PHASE 3
                  </Text>
                  <Text center>Access to the Elite</Text>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.text}>
                  <Text center fz16 semiBold>
                    PHASE 4
                  </Text>
                  <Text center>The Bullionaire Dynasty</Text>
                </div>
              </SwiperSlide>
            </Swiper>
            <button
              className={`${style.roadmapBody__phaseNav} phase-navigation-next`}
            >
              <svg
                width='8'
                height='11'
                viewBox='0 0 8 11'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6.55671e-07 5.5L8 0.500001L8 10.5L6.55671e-07 5.5Z'
                  fill='black'
                />
              </svg>
            </button>
          </div>

          <Swiper
            id='swiper2'
            modules={[Navigation, Controller]}
            className={`${style.roadmapBody__slider} roadmap-slider`}
            spaceBetween={0}
            slidesPerView={'auto'}
            onSwiper={handleSwiper2Init} // Инициализация второго слайдера
            autoHeight={isMobile}
          >
            <SwiperSlide>
              <div className={style.roadmapItem}>
                {phase1.map((item, index) => (
                  <div
                    key={index}
                    style={{ background: item.color }}
                    className={`${style.roadmapItem__card}`}
                  >
                    <span style={{ background: item.color }}></span>
                    <Text black fz16>
                      {item.name}
                    </Text>
                  </div>
                ))}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={style.roadmapItem}>
                {phase2.map((item, index) => (
                  <div
                    key={index}
                    style={{ background: item.color }}
                    className={`${style.roadmapItem__card}`}
                  >
                    <span style={{ background: item.color }}></span>
                    <Text black fz16>
                      {item.name}
                    </Text>
                  </div>
                ))}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={style.roadmapItem}>
                {phase3.map((item, index) => (
                  <div
                    key={index}
                    style={{ background: item.color }}
                    className={`${style.roadmapItem__card}`}
                  >
                    <span style={{ background: item.color }}></span>
                    <Text black fz16>
                      {item.name}
                    </Text>
                  </div>
                ))}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={style.roadmapItem}>
                {phase4.map((item, index) => (
                  <div
                    key={index}
                    style={{ background: item.color }}
                    className={`${style.roadmapItem__card}`}
                  >
                    <span style={{ background: item.color }}></span>
                    <Text black fz16>
                      {item.name}
                    </Text>
                  </div>
                ))}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default RoadmapSection;
