import { useEffect, useRef, useState } from 'react';
import style from './Main.module.scss';
import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import img5 from './assets/img5.png';
import img6 from './assets/img6.png';
import img7 from './assets/img7.png';
import img1Mob from './assets/img1-mob.png';
import img2Mob from './assets/img2-mob.png';
import img3Mob from './assets/img3-mob.png';
import img4Mob from './assets/img4-mob.png';
import img5Mob from './assets/img5-mob.png';
import img6Mob from './assets/img6-mob.png';
import img7Mob from './assets/img7-mob.png';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { useMediaQuery } from 'usehooks-ts';
import titleSvg from './assets/title.svg';
import Button from '../../../../components/Button';
import music from './assets/music.mp3';

gsap.registerPlugin(useGSAP);

const MainSection = () => {
  const container = useRef();
  const isMobile = useMediaQuery('(max-width:767px)');
  const audioRef = useRef();
  const [isAudioPlays, setIsAudioPlays] = useState(false);

  const logoTitleRef = useRef();
  const btnRef = useRef();
  const labelRef = useRef();
  const starsRef = useRef();

  const img1Ref = useRef();
  const img2Ref = useRef();
  const img3Ref = useRef();
  const img4Ref = useRef();
  const img5Ref = useRef();
  const img6Ref = useRef();
  const img7Ref = useRef();

  useGSAP(
    () => {
      const tl = gsap.timeline({
        onComplete: (self) => {},
      });

      tl.from(
        img1Ref.current,
        {
          y: '-100%',
          x: '-100%',
          duration: 1,
        },
        0
      );
      tl.from(
        img2Ref.current,
        {
          y: '-100%',
          duration: 1,
        },
        0
      );
      tl.from(
        img3Ref.current,
        {
          x: '100%',
          duration: 0.95,
        },
        0.05
      );
      tl.from(
        img4Ref.current,
        {
          x: '100%',
          duration: 1,
        },
        0
      );
      tl.from(
        img5Ref.current,
        {
          y: '100%',
          x: '100%',
          duration: 1,
        },
        0
      );
      tl.from(
        img6Ref.current,
        {
          y: '100%',
          x: '-100%',
          duration: 1,
        },
        0
      );
      tl.from(
        img7Ref.current,
        {
          x: '-100%',
          duration: 1,
        },
        0
      );
      tl.from(
        logoTitleRef.current,
        {
          y: '-100%',
          duration: 0.5,
          opacity: 0,
        },
        '-=0.5'
      );
      tl.from(
        labelRef.current,
        {
          y: '-100%',
          duration: 0.5,
          opacity: 0,
        },
        '-=0.4'
      );
      // tl.from(
      //   starsRef.current,
      //   {
      //     y: '-100%',
      //     duration: 0.5,
      //     opacity: 0,
      //   },
      //   '-=0.4'
      // );
      tl.from(
        btnRef.current,
        {
          y: '100%',
          duration: 0.5,
          opacity: 0,
        },
        '-=0.05'
      );
    },
    { scope: container }
  ); // <-- scope is for selector text (optional)

  useEffect(() => {
    const createStar = () => {
      const star = document.createElement('div');
      star.innerHTML = `<svg
        width='48'
        height='47'
        viewBox='0 0 48 47'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M25.4168 9.50751L24 5.43142L22.5832 9.50751L19.164 19.3439L8.75253 19.556L4.43812 19.644L7.87688 22.251L16.1752 28.5424L13.1597 38.5099L11.9101 42.6403L15.4522 40.1755L24 34.2274L32.5478 40.1755L36.0899 42.6403L34.8403 38.5099L31.8248 28.5424L40.1231 22.251L43.5619 19.644L39.2475 19.556L28.836 19.3439L25.4168 9.50751Z'
          fill='#FFAF00'
          stroke='black'
          stroke-width='3'
        />
      </svg>`;

      return star;
    };

    let count = 0;
    const maxStars = 5;
    const interval = setInterval(() => {
      if (count < maxStars) {
        starsRef.current.appendChild(createStar());
        count++;
      } else {
        clearInterval(interval); // Останавливаем интервал, когда добавлено 5 звезд
      }
    }, 1000);

    const timeout = setTimeout(() => {
      starsRef.current.classList.remove(style.isLoading);
    }, 5000);

    return () => {
      clearInterval(interval); // Очистка интервала при размонтировании компонента
      clearTimeout(timeout); // Очистка таймаута
    };
  }, []);

  const toggleAudio = () => {
    if (isAudioPlays) {
      setIsAudioPlays(false);
      audioRef.current.pause();
    } else {
      setIsAudioPlays(true);
      audioRef.current.play();
    }
  };

  return (
    <section ref={container} className={style.main}>
      <div onClick={toggleAudio} className={style.main__music}>
        {isAudioPlays ? (
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clip-path='url(#clip0_1720_28397)'>
              <path
                d='M15.8061 12.7194C16.4277 11.6793 16.8401 10.3638 16.9201 9.06211C16.9708 8.22666 16.7847 7.34965 16.3816 6.52497C15.7492 5.22947 14.6629 4.50171 13.6136 3.79857C12.8305 3.27391 12.0904 2.77848 11.5673 2.07072L11.4734 1.94456C11.1642 1.52914 10.8149 1.05833 10.7595 0.662906C10.7041 0.26287 10.341 -0.0217705 9.94406 0.00130852C9.54095 0.0290033 9.22861 0.362879 9.22861 0.767531V14.4764C8.58394 14.0856 7.79002 13.8456 6.92071 13.8456C4.80053 13.8456 3.07422 15.2257 3.07422 16.9228C3.07422 18.6199 4.80053 20 6.92071 20C9.0409 20 10.7672 18.6199 10.7672 16.9228V7.95586C11.9227 8.39744 13.8136 9.52215 14.3337 12.1485C14.2367 12.2916 14.1444 12.4455 14.0367 12.5686C13.7552 12.8871 13.7859 13.3733 14.106 13.6533C14.4229 13.9364 14.9091 13.9025 15.1907 13.5841C15.3969 13.3502 15.5799 13.0763 15.7553 12.7901C15.7738 12.7686 15.7908 12.7455 15.8061 12.7194Z'
                fill='black'
              />
            </g>
            <defs>
              <clipPath id='clip0_1720_28397'>
                <rect width='20' height='20' fill='white' />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clip-path='url(#clip0_1720_28673)'>
              <path
                d='M15.8061 12.7194C16.4277 11.6793 16.8401 10.3638 16.9201 9.06211C16.9708 8.22666 16.7847 7.34965 16.3816 6.52497C15.7492 5.22947 14.6629 4.50171 13.6136 3.79857C12.8305 3.27391 12.0904 2.77848 11.5673 2.07072L11.4734 1.94456C11.1642 1.52914 10.8149 1.05833 10.7595 0.662906C10.7041 0.26287 10.341 -0.0217705 9.94406 0.00130852C9.54095 0.0290033 9.22861 0.362879 9.22861 0.767531V14.4764C8.58394 14.0856 7.79002 13.8456 6.92071 13.8456C4.80053 13.8456 3.07422 15.2257 3.07422 16.9228C3.07422 18.6199 4.80053 20 6.92071 20C9.0409 20 10.7672 18.6199 10.7672 16.9228V7.95586C11.9227 8.39744 13.8136 9.52215 14.3337 12.1485C14.2367 12.2916 14.1444 12.4455 14.0367 12.5686C13.7552 12.8871 13.7859 13.3733 14.106 13.6533C14.4229 13.9364 14.9091 13.9025 15.1907 13.5841C15.3969 13.3502 15.5799 13.0763 15.7553 12.7901C15.7738 12.7686 15.7908 12.7455 15.8061 12.7194Z'
                fill='black'
              />
              <rect
                x='0.840267'
                y='11.7198'
                width='21.5272'
                height='2.7'
                transform='rotate(-35.2256 0.840267 11.7198)'
                fill='black'
                stroke='url(#paint0_linear_1720_28673)'
              />
            </g>
            <defs>
              <linearGradient
                id='paint0_linear_1720_28673'
                x1='1.53711'
                y1='12.6898'
                x2='22.0643'
                y2='12.6898'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#FF3A86' />
                <stop offset='1' stop-color='#FF3D83' />
              </linearGradient>
              <clipPath id='clip0_1720_28673'>
                <rect width='20' height='20' fill='white' />
              </clipPath>
            </defs>
          </svg>
        )}
        <audio loop ref={audioRef} src={music}></audio>
      </div>

      <div className={style.main__img}>
        <img ref={img1Ref} src={isMobile ? img1Mob : img1} alt='' />
        <img ref={img2Ref} src={isMobile ? img2Mob : img2} alt='' />
        <img ref={img3Ref} src={isMobile ? img3Mob : img3} alt='' />
        <img ref={img4Ref} src={isMobile ? img4Mob : img4} alt='' />
        <img ref={img5Ref} src={isMobile ? img5Mob : img5} alt='' />
        <img ref={img6Ref} src={isMobile ? img6Mob : img6} alt='' />
        <img ref={img7Ref} src={isMobile ? img7Mob : img7} alt='' />
      </div>

      <div className={style.mainBody}>
        <div className={style.mainLogo}>
          <div ref={logoTitleRef} className={style.mainLogo__title}>
            <img src={titleSvg} alt='' />
          </div>
          <div className={style.mainLogo__bottom}>
            <div ref={labelRef} className={style.mainLogo__label}>
              Lifestyle
              <span>Lifestyle</span>
              <svg
                width='165'
                height='30'
                viewBox='0 0 165 30'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g filter='url(#filter0_dii_1720_28393)'>
                  <path
                    d='M4 16.0736C56.3744 37.8042 163.823 -25.165 160.943 22'
                    stroke='white'
                    stroke-width='7'
                    stroke-linecap='round'
                  />
                </g>
                <defs>
                  <filter
                    id='filter0_dii_1720_28393'
                    x='0.499084'
                    y='0.501953'
                    width='164'
                    height='28.998'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                  >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                      in='SourceAlpha'
                      type='matrix'
                      values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                      result='hardAlpha'
                    />
                    <feOffset dy='4' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix
                      type='matrix'
                      values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0'
                    />
                    <feBlend
                      mode='normal'
                      in2='BackgroundImageFix'
                      result='effect1_dropShadow_1720_28393'
                    />
                    <feBlend
                      mode='normal'
                      in='SourceGraphic'
                      in2='effect1_dropShadow_1720_28393'
                      result='shape'
                    />
                    <feColorMatrix
                      in='SourceAlpha'
                      type='matrix'
                      values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                      result='hardAlpha'
                    />
                    <feOffset dy='1' />
                    <feComposite
                      in2='hardAlpha'
                      operator='arithmetic'
                      k2='-1'
                      k3='1'
                    />
                    <feColorMatrix
                      type='matrix'
                      values='0 0 0 0 0.972549 0 0 0 0 0.360784 0 0 0 0 0.729412 0 0 0 1 0'
                    />
                    <feBlend
                      mode='normal'
                      in2='shape'
                      result='effect2_innerShadow_1720_28393'
                    />
                    <feColorMatrix
                      in='SourceAlpha'
                      type='matrix'
                      values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                      result='hardAlpha'
                    />
                    <feOffset dy='-3' />
                    <feComposite
                      in2='hardAlpha'
                      operator='arithmetic'
                      k2='-1'
                      k3='1'
                    />
                    <feColorMatrix
                      type='matrix'
                      values='0 0 0 0 0.972549 0 0 0 0 0.360784 0 0 0 0 0.729412 0 0 0 1 0'
                    />
                    <feBlend
                      mode='normal'
                      in2='effect2_innerShadow_1720_28393'
                      result='effect3_innerShadow_1720_28393'
                    />
                  </filter>
                </defs>
              </svg>
            </div>
            <div
              ref={starsRef}
              className={`${style.mainLogo__stars} ${style.isLoading}`}
            ></div>
          </div>
        </div>
        <div ref={btnRef} className={style.mainBody__btn}>
          <Button>Presale now</Button>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
