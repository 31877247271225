import MainSection from './sections/Main';

const PressalePage = () => {
  return (
    <>
      <MainSection />
    </>
  );
};

export default PressalePage;
