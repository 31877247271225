import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import style from './Timer.module.scss';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import Button from '../../../../components/Button';

const Timer = ({ time, className }) => {
  const [activeSpans, setActiveSpans] = useState(0);
  const [currentPercent, setCurrentPercent] = useState(0);
  const totalSpans = 32; // Количество спанов в лоадере

  useEffect(() => {
    const updateLoader = () => {
      const now = new Date().getTime();
      const endTime = new Date(time).getTime();
      const totalDuration = 182.5 * 24 * 60 * 60 * 1000; // 6 месяцев в миллисекундах
      const timeLeft = Math.max(endTime - now, 0);
      const percentage = 1 - timeLeft / totalDuration; // Процент прошедшего времени

      setCurrentPercent(percentage * 100);
      setActiveSpans(Math.round(totalSpans * percentage));
    };

    // Обновляем каждую секунду
    const interval = setInterval(updateLoader, 1000);
    updateLoader();

    return () => clearInterval(interval);
  }, [time]);

  // Renderer function for the countdown
  const renderer = ({ days, hours }) => {
    return (
      <div className={style.timerItem}>
        <div className={style.timerItem__col}>
          <Title.H2 className={style.timerItem__value}>
            {Math.floor(days / 30)
              .toString()
              .padStart(2, '0')}
          </Title.H2>
          <Text grey fz16 className={style.timerItem__label}>
            Month
          </Text>
        </div>
        <svg
          width='4'
          height='16'
          viewBox='0 0 4 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='4' height='4' fill='white' />
          <rect y='12' width='4' height='4' fill='white' />
        </svg>
        <div className={style.timerItem__col}>
          <Title.H2 className={style.timerItem__value}>
            {(days % 30).toString().padStart(2, '0')}
          </Title.H2>
          <Text grey fz16 className={style.timerItem__label}>
            Days
          </Text>
        </div>
        <svg
          width='4'
          height='16'
          viewBox='0 0 4 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='4' height='4' fill='white' />
          <rect y='12' width='4' height='4' fill='white' />
        </svg>
        <div className={style.timerItem__col}>
          <Title.H2 className={style.timerItem__value}>
            {hours.toString().padStart(2, '0')}
          </Title.H2>
          <Text grey fz16 className={style.timerItem__label}>
            Hours
          </Text>
        </div>
      </div>
    );
  };

  return (
    <div className={`${style.timer} ${className}`}>
      <Text grey uppercase fz16 className={style.timer__title}>
        Presale Launching in
      </Text>
      <div className={style.timer__loader}>
        <div
          style={{ width: `${currentPercent}%` }}
          className={style.timer__loaderWrapper}
        >
          <span></span>
        </div>
        {/* {[...Array(totalSpans)].map((_, index) => (
          <span
            key={index}
            className={index < activeSpans ? style.active : ''}
          ></span>
        ))} */}
      </div>
      <Countdown date={time} renderer={renderer} />
      <div className={style.timerForm}>
        <label className={style.timerForm__input}>
          <input type='text' placeholder='E-mail' />
        </label>
        <Button className={style.timerForm__btn}>Submit</Button>
      </div>
      <Text grey fz16 className={style.timer__info}>
        Get exclusive early access to the Bullionaire presale
      </Text>
    </div>
  );
};

export default Timer;
