import { useRef, useState } from 'react';
import Text from '../../../../components/Text';
import style from './Presale.module.scss';
import Button from '../../../../components/Button';
import ethIcon from './assets/eth.svg';

const Presale = ({ className }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const selectRef = useRef();

  const toggleSelect = () => {
    if (selectRef.current.classList.contains(style.open)) {
      selectRef.current.classList.remove(style.open);
    } else {
      selectRef.current.classList.add(style.open);
    }
  };

  return (
    <div className={`${style.presale} ${className}`}>
      <div className={style.presaleTabs}>
        <button
          onClick={() => setActiveIndex(0)}
          className={`${style.presaleTabs__item} ${
            activeIndex === 0 && style.active
          }`}
        >
          Buy
        </button>
        <button
          onClick={() => setActiveIndex(1)}
          className={`${style.presaleTabs__item} ${
            activeIndex === 1 && style.active
          }`}
        >
          Stake
        </button>
        <button
          onClick={() => setActiveIndex(2)}
          className={`${style.presaleTabs__item} ${
            activeIndex === 2 && style.active
          }`}
        >
          History
        </button>
      </div>
      <div className={style.presaleContent}>
        {activeIndex === 0 && (
          <div className={style.presaleBuy}>
            <Text fz24 bold center>
              Stake
            </Text>
            <Text center fz16 className={style.presaleBuy__percent}>
              Up to 80% APY
            </Text>
            <div className={style.presaleBuy__progress}>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span className={style.active}></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className={style.presaleBuy__progressBottom}>
              <Text fz16>USD Raised</Text>
              <Text fz16>$1,252.532 / $2,000.000</Text>
            </div>
            <div className={style.presaleBuy__available}>
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clip-path='url(#clip0_1720_28158)'>
                  <path
                    d='M7.99967 10.6663V7.99967M7.99967 5.33301H8.00634M14.6663 7.99967C14.6663 11.6816 11.6816 14.6663 7.99967 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99967C1.33301 4.31778 4.31778 1.33301 7.99967 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99967Z'
                    stroke='white'
                    stroke-opacity='0.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1720_28158'>
                    <rect width='16' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>
              <Text grey>Available to Stake:</Text>
              <Text>742,392 $BULL</Text>
            </div>
            <div className={style.presaleBuy__inputs}>
              <div className={style.input}>
                <div className={style.inputWrapper}>
                  <Text className={style.inputWrapper__label} grey>
                    You pay
                  </Text>
                  <input
                    className={style.inputWrapper__input}
                    type='text'
                    placeholder=''
                    defaultValue={0}
                  />
                </div>
                <div ref={selectRef} className={style.inputSelect}>
                  <div
                    onClick={toggleSelect}
                    className={style.inputSelect__top}
                  >
                    <div className={style.inputSelect__topIcon}>
                      <img src={ethIcon} alt='' />
                    </div>
                    <Text className={style.inputSelect__topName}>ETH</Text>
                    <Text grey className={style.inputSelect__topNetwork}>
                      (ERC-20)
                    </Text>
                    <div className={style.inputSelect__topArrow}>
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 6L8 10L12 6'
                          stroke='white'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    </div>
                  </div>
                  <div className={style.inputSelect__dropdown}>
                    <div className={style.inputSelect__item}>
                      <div className={style.inputSelect__itemIcon}>
                        <img src={ethIcon} alt='' />
                      </div>
                      <Text className={style.inputSelect__itemName}>ETH</Text>
                      <Text grey className={style.inputSelect__itemNetwork}>
                        (ERC-20)
                      </Text>
                    </div>
                    <div className={style.inputSelect__item}>
                      <div className={style.inputSelect__itemIcon}>
                        <img src={ethIcon} alt='' />
                      </div>
                      <Text className={style.inputSelect__itemName}>ETH</Text>
                      <Text grey className={style.inputSelect__itemNetwork}>
                        (ERC-20)
                      </Text>
                    </div>
                    <div className={style.inputSelect__item}>
                      <div className={style.inputSelect__itemIcon}>
                        <img src={ethIcon} alt='' />
                      </div>
                      <Text className={style.inputSelect__itemName}>ETH</Text>
                      <Text grey className={style.inputSelect__itemNetwork}>
                        (ERC-20)
                      </Text>
                    </div>
                    <div className={style.inputSelect__item}>
                      <div className={style.inputSelect__itemIcon}>
                        <img src={ethIcon} alt='' />
                      </div>
                      <Text className={style.inputSelect__itemName}>ETH</Text>
                      <Text grey className={style.inputSelect__itemNetwork}>
                        (ERC-20)
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.input}>
                <div className={style.inputWrapper}>
                  <Text className={style.inputWrapper__label} grey>
                    You Receive $BULL
                  </Text>
                  <input
                    className={style.inputWrapper__input}
                    type='text'
                    placeholder=''
                    defaultValue={0}
                  />
                </div>
              </div>
            </div>
            <div className={style.presaleBuy__btns}>
              <Button className={style.presaleBuy__btnsConnect}>
                Connect wallet
              </Button>
              <button className={style.presaleBuy__btnsHow}>How to Buy</button>
            </div>
          </div>
        )}
        {activeIndex === 1 && (
          <div className={style.presaleStake}>
            <Text fz24 bold center>
              Stake
            </Text>
            <Text center fz16 className={style.presaleBuy__percent}>
              Up to 80% APY
            </Text>
            <div className={style.presaleStake__info}>
              <div className={style.row}>
                <div className={style.col}>
                  <Text fz16 center>
                    778,666.66 $BULL
                  </Text>
                  <Text className={style.col__label} center grey>
                    Staked
                  </Text>
                </div>
                <div className={style.col}>
                  <Text fz16 center>
                    778,666.66 $BULL
                  </Text>
                  <Text className={style.col__label} center grey>
                    Daily Staking
                  </Text>
                </div>
              </div>
              <div className={style.row}>
                <div className={style.col}>
                  <Text fz16 center>
                    43,732.86 $BULL
                  </Text>
                  <Text className={style.col__label} center grey>
                    Total Earnings
                  </Text>
                </div>
              </div>
            </div>
            <div className={style.presaleBuy__available}>
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clip-path='url(#clip0_1720_28158)'>
                  <path
                    d='M7.99967 10.6663V7.99967M7.99967 5.33301H8.00634M14.6663 7.99967C14.6663 11.6816 11.6816 14.6663 7.99967 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99967C1.33301 4.31778 4.31778 1.33301 7.99967 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99967Z'
                    stroke='white'
                    stroke-opacity='0.4'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1720_28158'>
                    <rect width='16' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>
              <Text grey>Available to Stake:</Text>
              <Text>742,392 $BULL</Text>
            </div>
            <div className={style.presaleBuy__inputs}>
              <div className={style.input}>
                <div className={style.inputWrapper}>
                  <Text className={style.inputWrapper__label} grey>
                    You Receive $BULL
                  </Text>
                  <input
                    className={style.inputWrapper__input}
                    type='text'
                    placeholder=''
                    defaultValue={0}
                  />
                </div>
              </div>
            </div>
            <div className={style.presaleStake__btns}>
              <Button className={style.presaleStake__btnsItem}>Stake</Button>
              <Button className={style.presaleStake__btnsItem}>unStake</Button>
            </div>
          </div>
        )}
        {activeIndex === 2 && (
          <div className={style.presaleHistory}>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.pending}`}
              >
                Pending
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
            <div className={style.presaleHistory__item}>
              <div className={style.presaleHistory__itemIcon}>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.41784 3.33317C9.46995 2.30218 10.9109 1.6665 12.5003 1.6665C15.722 1.6665 18.3337 4.27818 18.3337 7.49984C18.3337 9.08928 17.698 10.5303 16.6669 11.5824M13.3337 12.4998C13.3337 15.7215 10.722 18.3332 7.50033 18.3332C4.27866 18.3332 1.66699 15.7215 1.66699 12.4998C1.66699 9.27818 4.27866 6.6665 7.50033 6.6665C10.722 6.6665 13.3337 9.27818 13.3337 12.4998Z'
                    stroke='white'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <Text fz16 grey className={style.presaleHistory__itemPrice}>
                $3.57
              </Text>
              <Text fz16 className={style.presaleHistory__itemCount}>
                +11,900 $BULL
              </Text>
              <div
                className={`${style.presaleHistory__itemStatus} ${style.completed}`}
              >
                Completed
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Presale;
