import style from './Title.module.scss';
import { Fade } from 'react-reveal';

const Title = {
  H1: ({ className, big, center, children }) => {
    return (
      <div
        className={`${style.title} ${style.h1} ${center && style.center} ${
          big && style.big
        } ${className}`}
      >
        {children}
      </div>
    );
  },
  H2: ({ className, big, center, children }) => {
    return (
      <div
        className={`${style.title} ${style.h2} ${center && style.center} ${
          big && style.big
        } ${className}`}
      >
        {children}
      </div>
    );
  },
  Row: ({ children }) => {
    return (
      <div className={style.row}>
        <Fade up cascade duration={1000}>
          {children}
        </Fade>
      </div>
    );
  },
};

export default Title;
