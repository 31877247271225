import style from './Main.module.scss';
import Title from '../../../../components/Title';
import bgLeft from './assets/bg-left.jpg';
import bgRight from './assets/bg-right.jpg';
import bgTop from './assets/bg-top.jpg';
import Timer from '../../modules/Timer';
import Partners from '../../modules/Patners';
import Text from '../../../../components/Text';
import lights from './assets/lights.png';
import lightsMob from './assets/lights-mob.png';
import lightsMobTop from './assets/lights-mob-top.png';
import { useMediaQuery } from 'usehooks-ts';

const MainSection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <section className={style.main}>
      <div className={`${style.main__bg} ${style.left}`}>
        <img src={bgLeft} alt='' />
      </div>
      <div className={`${style.main__bg} ${style.right}`}>
        <img src={bgRight} alt='' />
      </div>
      <div className={`${style.main__bg} ${style.top}`}>
        <img src={bgTop} alt='' />
      </div>

      <div className={style.main__lights}>
        <img src={isMobile ? lightsMob : lights} alt='' />
      </div>
      <div className={`${style.main__lights} ${style.top}`}>
        <img src={lightsMobTop} alt='' />
      </div>

      <div className='__container'>
        <div className={style.mainBody}>
          <div className={style.main__stars}>
            <svg
              width='57'
              height='54'
              viewBox='0 0 57 54'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M29.9168 9.50751L28.5 5.43142L27.0832 9.50751L22.7236 22.0494L9.44831 22.32L5.1339 22.4079L8.57265 25.015L19.1536 33.0368L15.3086 45.746L14.059 49.8764L17.6011 47.4116L28.5 39.8274L39.3989 47.4116L42.941 49.8764L41.6914 45.746L37.8464 33.0368L48.4273 25.015L51.8661 22.4079L47.5517 22.32L34.2764 22.0494L29.9168 9.50751Z'
                fill='#FFAF00'
                stroke='black'
                stroke-width='3'
              />
            </svg>
            <svg
              width='57'
              height='54'
              viewBox='0 0 57 54'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M29.9168 9.50751L28.5 5.43142L27.0832 9.50751L22.7236 22.0494L9.44831 22.32L5.1339 22.4079L8.57265 25.015L19.1536 33.0368L15.3086 45.746L14.059 49.8764L17.6011 47.4116L28.5 39.8274L39.3989 47.4116L42.941 49.8764L41.6914 45.746L37.8464 33.0368L48.4273 25.015L51.8661 22.4079L47.5517 22.32L34.2764 22.0494L29.9168 9.50751Z'
                fill='#FFAF00'
                stroke='black'
                stroke-width='3'
              />
            </svg>
            <svg
              width='57'
              height='54'
              viewBox='0 0 57 54'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M29.9168 9.50751L28.5 5.43142L27.0832 9.50751L22.7236 22.0494L9.44831 22.32L5.1339 22.4079L8.57265 25.015L19.1536 33.0368L15.3086 45.746L14.059 49.8764L17.6011 47.4116L28.5 39.8274L39.3989 47.4116L42.941 49.8764L41.6914 45.746L37.8464 33.0368L48.4273 25.015L51.8661 22.4079L47.5517 22.32L34.2764 22.0494L29.9168 9.50751Z'
                fill='#FFAF00'
                stroke='black'
                stroke-width='3'
              />
            </svg>
            <svg
              width='57'
              height='54'
              viewBox='0 0 57 54'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M29.9168 9.50751L28.5 5.43142L27.0832 9.50751L22.7236 22.0494L9.44831 22.32L5.1339 22.4079L8.57265 25.015L19.1536 33.0368L15.3086 45.746L14.059 49.8764L17.6011 47.4116L28.5 39.8274L39.3989 47.4116L42.941 49.8764L41.6914 45.746L37.8464 33.0368L48.4273 25.015L51.8661 22.4079L47.5517 22.32L34.2764 22.0494L29.9168 9.50751Z'
                fill='#FFAF00'
                stroke='black'
                stroke-width='3'
              />
            </svg>
            <svg
              width='57'
              height='54'
              viewBox='0 0 57 54'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M29.9168 9.50751L28.5 5.43142L27.0832 9.50751L22.7236 22.0494L9.44831 22.32L5.1339 22.4079L8.57265 25.015L19.1536 33.0368L15.3086 45.746L14.059 49.8764L17.6011 47.4116L28.5 39.8274L39.3989 47.4116L42.941 49.8764L41.6914 45.746L37.8464 33.0368L48.4273 25.015L51.8661 22.4079L47.5517 22.32L34.2764 22.0494L29.9168 9.50751Z'
                fill='#FFAF00'
                stroke='black'
                stroke-width='3'
              />
            </svg>
          </div>
          <Title.H1 center className={style.main__title}>
            Bullionaire <br /> presale
          </Title.H1>
          <div className={style.main__label}>
            Join now
            <svg
              width='302'
              height='35'
              viewBox='0 0 302 35'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g filter='url(#filter0_dii_2034_7538)'>
                <path
                  d='M3 5.80919C77.6916 39.9992 329.925 -33.7212 295.853 30'
                  stroke='white'
                  stroke-width='4.33354'
                  stroke-linecap='round'
                />
              </g>
              <defs>
                <filter
                  id='filter0_dii_2034_7538'
                  x='0.833008'
                  y='0.833984'
                  width='300.334'
                  height='33.697'
                  filterUnits='userSpaceOnUse'
                  color-interpolation-filters='sRGB'
                >
                  <feFlood flood-opacity='0' result='BackgroundImageFix' />
                  <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                  />
                  <feOffset dy='2.36375' />
                  <feComposite in2='hardAlpha' operator='out' />
                  <feColorMatrix
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0'
                  />
                  <feBlend
                    mode='normal'
                    in2='BackgroundImageFix'
                    result='effect1_dropShadow_2034_7538'
                  />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='effect1_dropShadow_2034_7538'
                    result='shape'
                  />
                  <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                  />
                  <feOffset dy='0.787917' />
                  <feComposite
                    in2='hardAlpha'
                    operator='arithmetic'
                    k2='-1'
                    k3='1'
                  />
                  <feColorMatrix
                    type='matrix'
                    values='0 0 0 0 0.972549 0 0 0 0 0.360784 0 0 0 0 0.729412 0 0 0 1 0'
                  />
                  <feBlend
                    mode='normal'
                    in2='shape'
                    result='effect2_innerShadow_2034_7538'
                  />
                  <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                  />
                  <feOffset dy='-1.57583' />
                  <feComposite
                    in2='hardAlpha'
                    operator='arithmetic'
                    k2='-1'
                    k3='1'
                  />
                  <feColorMatrix
                    type='matrix'
                    values='0 0 0 0 0.972549 0 0 0 0 0.360784 0 0 0 0 0.729412 0 0 0 1 0'
                  />
                  <feBlend
                    mode='normal'
                    in2='effect2_innerShadow_2034_7538'
                    result='effect3_innerShadow_2034_7538'
                  />
                </filter>
              </defs>
            </svg>
          </div>
          <Timer time={1741631601 * 1000} className={style.main__timer} />
          <Partners className={style.main__marquee} />
          <div className={style.mainBottom}>
            <div className={style.mainBottom__item}>
              <Text bold fz24 className={style.mainBottom__itemTitle}>
                Join the biggest crypto meme coin launch:
              </Text>
              <Text className={style.mainBottom__itemText}>
                Don’t miss out to be the first to get in on the biggest crypto
                presale of the year. The $BULL token is set to launch soon.
              </Text>
            </div>
            <div className={style.mainBottom__item}>
              <Text bold fz24 className={style.mainBottom__itemTitle}>
                Be First in Line:
              </Text>
              <Text className={style.mainBottom__itemText}>
                Everyone who signs up now will receive an exclusive email
                invitation to buy $BULL tokens at the absolute lowest price,
                available only during the exclusive Stage 1 presale.
              </Text>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
