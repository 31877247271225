import AdvantageSection from './sections/Advantage';
import BuiltSection from './sections/Built';
import CoinSection from './sections/Coin';
import GetItSection from './sections/GetIt';
import HowSection from './sections/How';
import LegalSection from './sections/Legal';
import MainSection from './sections/Main';
import RoadmapSection from './sections/Roadmap';
import SafetySection from './sections/Safety';
import StakingSection from './sections/Staking';
import TeamSection from './sections/Team';
import TokenomicsSection from './sections/Tocenomics';

const HomePage = () => {
  return (
    <>
      <MainSection />
      <CoinSection />
      <AdvantageSection />
      <HowSection />
      <TokenomicsSection />
      <GetItSection />
      <StakingSection />
      <BuiltSection />
      <SafetySection />
      <RoadmapSection />
      <TeamSection />
      <LegalSection />
    </>
  );
};

export default HomePage;
