import { useMediaQuery } from 'usehooks-ts';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import style from './How.module.scss';
import lights from './assets/lights.png';

const HowSection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <section className={style.how}>
      <div className={style.how__lights}>
        <img src={lights} alt='' />
      </div>
      <div className='__container'>
        <div className={style.howMain}>
          <Title.H2 className={style.howMain__title}>
            {!isMobile && (
              <>
                <Title.Row>How to Join the</Title.Row>
                <Title.Row>Bullionaire Lifestyle</Title.Row>
              </>
            )}
            {isMobile && (
              <>
                <Title.Row>How to Join the</Title.Row>
                <Title.Row>Bullionaire</Title.Row>
                <Title.Row>Lifestyle</Title.Row>
              </>
            )}
          </Title.H2>
          <Text className={style.howMain__text}>
            Joining the Bullionaire presale is straightforward and opens the
            door to exclusive rewards and privileges.
          </Text>
          <Button className={style.howMain__btn}>Join now</Button>
        </div>
        <div className={style.howBody}>
          <div className={style.howBody__col}>
            <div className={style.howBody__colItem}>
              <Text fz24>Choose Your Purchase Currency</Text>
              <Text>
                The presale accepts multiple currencies, so you can participate
                using USDT, ETH, SOL, or BNB. Ensure you have the currency of
                choice available in your wallet.
              </Text>
            </div>
            {!isMobile && (
              <div className={style.howBody__colLine}>
                <span></span>
              </div>
            )}
            <div className={style.howBody__colItem}>
              <Text fz24>Connect to the Presale Widget</Text>
              <Text>
                Download a secure wallet like Trust Wallet to participate in the
                presale. Keep your recovery phrase safe-this is your access to
                the Bullionaire lifestyle.
              </Text>
            </div>
          </div>
          <div className={style.howBody__col}>
            <div className={style.howBody__colItem}>
              <Text fz24>Buy $BULL</Text>
              <Text>
                Select the amount you’d like to purchase, confirm the
                transaction, and secure your $BULL. Remember, the earlier you
                join, the more tokens you’ll receive. Your tokens will be
                available to claim at launch.
              </Text>
            </div>
            {!isMobile && (
              <div className={style.howBody__colLine}>
                <span></span>
              </div>
            )}
            <div className={style.howBody__colItem}>
              <Text fz24>Set Up Your Wallet</Text>
              <Text>
                Download a secure wallet like Trust Wallet to participate in the
                presale. Keep your recovery phrase safe-this is your access to
                the Bullionaire lifestyle.
              </Text>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowSection;
