import style from './Coin.module.scss';
import Title from '../../../../components/Title';
import Text from '../../../../components/Text';
import img from './assets/img.png';
import { useMediaQuery } from 'usehooks-ts';

const CoinSection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <section className={style.coin}>
      <div className='__container'>
        <div className={style.coinBody}>
          <Title.H1 className={style.coinBody__title} center>
            <Title.Row>Bullionaire Coin</Title.Row>
          </Title.H1>
          {isMobile && (
            <div className={style.coinBody__text}>
              <p>
                <Text center>
                  In a world overrun with fleeting meme coins and empty
                  promises, Bullionaire Coin stands as your loyal guard, cutting
                  through the noise and delivering a lifestyle that others can
                  only imagine.
                </Text>
              </p>
              <p>
                <Text center>
                  While other tokens hype trends, $BULL provides unwavering
                  commitment to true luxury, loyalty, and exclusivity.
                </Text>
              </p>
            </div>
          )}
          <div className={style.coinBody__img}>
            <img src={img} alt='' />
          </div>
          <div className={style.coinBody__bottom}>
            <div className={style.coinBody__bottomCol}>
              <Text center={isMobile}>
                $BULL isn’t just another coin. It’s your trusted companion in a
                world of pretenders.
              </Text>
            </div>
            {!isMobile && (
              <div className={style.coinBody__bottomCol}>
                <Text>
                  In a world overrun with fleeting meme coins and empty
                  promises, Bullionaire Coin stands as your loyal guard, cutting
                  through the noise and delivering a lifestyle that others can
                  only imagine.
                </Text>
                <Text>
                  While other tokens hype trends, $BULL provides unwavering
                  commitment to true luxury, loyalty, and exclusivity.
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoinSection;
