import { useRef } from 'react';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import style from './Team.module.scss';

import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import img5 from './assets/img5.png';
import img6 from './assets/img6.png';
import img7 from './assets/img7.png';
import img1Mob from './assets/img1-mob.png';
import img2Mob from './assets/img2-mob.png';
import img3Mob from './assets/img3-mob.png';
import img4Mob from './assets/img4-mob.png';
import img5Mob from './assets/img5-mob.png';
import img6Mob from './assets/img6-mob.png';
import img7Mob from './assets/img7-mob.png';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useMediaQuery } from 'usehooks-ts';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const TeamSection = () => {
  const container = useRef();
  const isMobile = useMediaQuery('(max-width:767px)');

  const img1Ref = useRef();
  const img2Ref = useRef();
  const img3Ref = useRef();
  const img4Ref = useRef();
  const img5Ref = useRef();
  const img6Ref = useRef();
  const img7Ref = useRef();

  useGSAP(
    () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          start: 'top 50%',
          markers: false,
        },
      });

      tl.from(
        img1Ref.current,
        {
          y: '-100%',
          duration: 1,
        },
        0
      );
      tl.from(
        img2Ref.current,
        {
          x: '-100%',
          duration: 1,
        },
        0
      );
      tl.from(
        img3Ref.current,
        {
          x: '100%',
          duration: 0.95,
        },
        0.05
      );
      tl.from(
        img4Ref.current,
        {
          x: '-100%',
          duration: 1,
        },
        0
      );
      tl.from(
        img5Ref.current,
        {
          x: '100%',
          duration: 1,
        },
        0
      );
      tl.from(
        img6Ref.current,
        {
          y: '100%',
          x: '-100%',
          duration: 1,
        },
        0
      );
      tl.from(
        img7Ref.current,
        {
          x: '-100%',
          duration: 1,
        },
        0
      );
    },
    { scope: container }
  ); // <-- scope is for selector text (optional)

  return (
    <section ref={container} className={style.team}>
      <div className={style.teamImg}>
        <div ref={img1Ref} className={style.teamImg__item}>
          <img src={isMobile ? img1Mob : img1} alt='' />
        </div>
        <div ref={img2Ref} className={style.teamImg__item}>
          <img src={isMobile ? img2Mob : img2} alt='' />
        </div>
        <div ref={img3Ref} className={style.teamImg__item}>
          <img src={isMobile ? img3Mob : img3} alt='' />
        </div>
        <div ref={img4Ref} className={style.teamImg__item}>
          <img src={isMobile ? img4Mob : img4} alt='' />
        </div>
        <div ref={img5Ref} className={style.teamImg__item}>
          <img src={isMobile ? img5Mob : img5} alt='' />
        </div>
        <div ref={img6Ref} className={style.teamImg__item}>
          <img src={isMobile ? img6Mob : img6} alt='' />
        </div>
        <div ref={img7Ref} className={style.teamImg__item}>
          <img src={isMobile ? img7Mob : img7} alt='' />
        </div>
      </div>
      <div className={'__container'}>
        <div className={style.teamBody}>
          <Title.H1 big center className={style.teamBody__title}>
            <Title.Row>Meet the</Title.Row>
            <Title.Row>Team</Title.Row>
          </Title.H1>
          <Text center className={style.teamBody__text}>
            Our team is composed of blockchain experts, luxury lifestyle
            innovators, finance pro and seasoned luxury concierge specialists.
          </Text>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
