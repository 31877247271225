import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import style from './Advantage.module.scss';
import lights from './assets/lights.png';

import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import img1Mob from './assets/img1-mob.png';
import img2Mob from './assets/img2-mob.png';
import img3Mob from './assets/img3-mob.png';
import img4Mob from './assets/img4-mob.png';
import plane from './assets/plane.png';
import { useGSAP } from '@gsap/react';
import { useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useMediaQuery } from 'usehooks-ts';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

const AdvantageSection = () => {
  const container = useRef();
  const lineRef = useRef();
  const isMobile = useMediaQuery('(max-width:767px)');

  useGSAP(
    () => {
      const imgItemList = container.current.querySelectorAll(
        `.${style.advantageBody__imgItem}`
      );
      const textItemList = container.current.querySelectorAll(
        `.${style.advantageBody__textItem}`
      );

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          start: 'top',
          end: '+=600%',
          pin: true,
          scrub: 1,
          markers: false,
        },
      });

      imgItemList.forEach((img, index) => {
        if (index !== 0) {
          if (index === 3) {
            tl.from(
              img,
              {
                x: `-100%`,
                opacity: 0,
                duration: 1,
              },
              index + 2
            );
          } else {
            if (index !== 1) {
              tl.from(
                img,
                {
                  x: `-100%`,
                  opacity: 0,
                  duration: 1,
                },
                index + 1
              );
              tl.to(
                img,
                {
                  x: `100%`,
                  opacity: 0,
                  duration: 1,
                },
                index + 2
              );
            } else {
              tl.from(
                img,
                {
                  x: `-100%`,
                  opacity: 0,
                  duration: 1,
                },
                index
              );
              tl.to(
                img,
                {
                  x: `100%`,
                  opacity: 0,
                  duration: 1,
                },
                index + 1
              );
            }
          }
        } else {
          tl.to(
            img,
            {
              x: `100%`,
              opacity: 0,
              duration: 1,
            },
            0
          );
        }
      });

      textItemList.forEach((text, index) => {
        if (index !== 0) {
          if (index === 3) {
            tl.from(
              text,
              {
                x: `-100%`,
                opacity: 0,
                duration: 1,
              },
              index + 2
            );
          } else {
            if (index !== 1) {
              tl.from(
                text,
                {
                  x: `-100%`,
                  opacity: 0,
                  duration: 1,
                },
                index + 1
              );
              tl.to(
                text,
                {
                  x: `100%`,
                  opacity: 0,
                  duration: 1,
                },
                index + 2
              );
            } else {
              tl.from(
                text,
                {
                  x: `-100%`,
                  opacity: 0,
                  duration: 1,
                },
                index
              );
              tl.to(
                text,
                {
                  x: `100%`,
                  opacity: 0,
                  duration: 1,
                },
                index + 1
              );
            }
          }
        } else {
          tl.to(
            text,
            {
              x: `100%`,
              opacity: 0,
              duration: 1,
            },
            0
          );
        }
      });

      tl.to(
        lineRef.current,
        {
          width: `100%`,
          duration: 8,
        },
        0
      );
    },
    { scope: container }
  );

  return (
    <section ref={container} className={style.advantage}>
      <div className={style.advantage__lights}>
        <img src={lights} alt='' />
      </div>
      <div className={style.advantage__progress}>
        <div className={style.advantage__progressCols}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={style.advantage__progressLine}>
          <span ref={lineRef}></span>
        </div>
      </div>
      <div className='__container'>
        <div className={style.advantageBody}>
          <Title.H1 className={style.advantageBody__title}>
            <Title.Row>Advantage</Title.Row>
          </Title.H1>
          <div className={style.advantageBody__img}>
            <div className={style.advantageBody__imgItem}>
              <img src={isMobile ? img1Mob : img1} alt='' />
              <div className={style.plane}>
                <img src={plane} alt='' />
              </div>
            </div>
            <div className={style.advantageBody__imgItem}>
              <img src={isMobile ? img2Mob : img2} alt='' />
            </div>
            <div className={style.advantageBody__imgItem}>
              <img src={isMobile ? img3Mob : img3} alt='' />
            </div>
            <div className={style.advantageBody__imgItem}>
              <img src={isMobile ? img4Mob : img4} alt='' />
            </div>
          </div>
          <div className={style.advantageBody__text}>
            {/* {currentIndex === 0 && ( */}
            <div className={style.advantageBody__textItem}>
              <Text fz24 center={isMobile}>
                Private Jet Access
              </Text>
              <Text grey center={isMobile}>
                $BULL has partnered with top private aviation services to ensure
                Bullionaire holders travel like VIP’s skip the lines and fly in
                style with seamless access to private flights.
              </Text>
            </div>
            {/* )} */}
            {/* {currentIndex === 1 && ( */}
            <div className={style.advantageBody__textItem}>
              <Text fz24 center={isMobile}>
                Luxury Spa Retreats
              </Text>
              <Text grey center={isMobile}>
                Bullionaire’s exclusive spa partners offer you entry to
                world-renowned wellness retreats, providing rejuvenation that
                goes beyond ordinary relaxation.
              </Text>
            </div>
            {/* )} */}
            {/* {currentIndex === 2 && ( */}
            <div className={style.advantageBody__textItem}>
              <Text fz24 center={isMobile}>
                Exclusive VIP Entry
              </Text>
              <Text grey center={isMobile}>
                Bullionaire gives you access to the most sought-after events.
                With $BULL, you hold a ticket to sold-out concerts, fashion
                shows, and premium sporting events. Experiences other tokens
                can’t even promise.
              </Text>
            </div>
            {/* )} */}
            {/* {currentIndex === 3 && ( */}
            <div className={style.advantageBody__textItem}>
              <Text fz24 center={isMobile}>
                Concierge Service for Alpha Holders
              </Text>
              <Text grey center={isMobile}>
                For our highest-tier holders, Bullionaire has partnered with
                premium concierge services. This personal touch ensures every
                experience aligns with Bullionaire’s standards of excellence.
              </Text>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvantageSection;
