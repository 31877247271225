import { useMediaQuery } from 'usehooks-ts';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import style from './Built.module.scss';

import lightsLeft from './assets/lights-left.png';
import lightsRight from './assets/lights-right.png';
import lightsMobile from './assets/lights-mob.png';
import coinWebm from './assets/coin.webm';
import coinMp4 from './assets/coin.mp4';

const BuiltSection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <section className={style.built}>
      {isMobile && (
        <div className={`${style.built__lights}`}>
          <img src={lightsMobile} alt='' />
        </div>
      )}
      {!isMobile && (
        <>
          <div className={`${style.built__lights} ${style.left}`}>
            <img src={lightsLeft} alt='' />
          </div>
          <div className={`${style.built__lights} ${style.right}`}>
            <img src={lightsRight} alt='' />
          </div>
        </>
      )}

      <div className='__container'>
        <div className={style.builtTop}>
          <div className={style.builtTop__text}>
            <Title.H2>
              {!isMobile && (
                <>
                  <Title.Row>Built on Solana for</Title.Row>
                  <Title.Row>Speed & Security</Title.Row>
                </>
              )}
              {isMobile && (
                <>
                  <Title.Row>Built on Solana</Title.Row>
                  <Title.Row>for Speed &</Title.Row>
                  <Title.Row>Security</Title.Row>
                </>
              )}
            </Title.H2>
            <Text>
              Bullionaire leverages Solana’s high-performance blockchain, chosen
              for its unmatched speed, low costs, and robust security all
              critical for a seamless luxury experience.
            </Text>
          </div>
          <div className={style.builtTop__sol}>
            <video muted loop autoPlay playsInline>
              <source src={coinMp4} type='video/mp4' />
              <source src={coinWebm} type='video/webm' />
            </video>
          </div>
        </div>
        <div className={style.builtBody}>
          <div className={style.builtCard}>
            <Title.H2 className={`${style.builtCard__title} ${style.big}`}>
              <Title.Row>65,000+</Title.Row>
            </Title.H2>
            <Text className={style.builtCard__text}>
              Solana’s speed ensures near-instant transactions, allowing $BULL
              holders to stake, trade, and redeem rewards without delay.
            </Text>
          </div>
          <div className={style.builtCard}>
            <Text className={style.builtCard__title}>
              <Title.Row>Low Fees</Title.Row>
            </Text>
            <Text className={style.builtCard__text}>
              Average transaction costs on Solana are as low as $0.00025,
              meaning more value stays in your wallet.
            </Text>
          </div>
          <div className={style.builtCard}>
            <Text className={style.builtCard__title}>
              <Title.Row>Decentralized Security</Title.Row>
            </Text>
            <Text className={style.builtCard__text}>
              Solana’s speed ensures near-instant transactions, allowing $BULL
              holders to stake, trade, and redeem rewards without delay.
            </Text>
          </div>
          <div className={style.builtCard}>
            <Text className={style.builtCard__title}>
              <Title.Row>Sustainable Design</Title.Row>
            </Text>
            <Text className={style.builtCard__text}>
              Solana’s speed ensures near-instant transactions, allowing $BULL
              holders to stake, trade, and redeem rewards without delay.
            </Text>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuiltSection;
