import style from './Button.module.scss';

const Button = ({ customRef, className, children }) => {
  return (
    <button ref={customRef} className={`${style.button} ${className}`}>
      {children}
    </button>
  );
};

export default Button;
